.MuiContainer-root {
  background-color: black;
  z-index: 1111;
  position: fixed;
}

.MainContainer {
  width: 100%;
}

.MuiPaper-root-MuiAppBar-root {
  background-color: black;
  width: 100% !important;
}
.logo {
  display: block;
  padding-left: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.logo-mob {
  display: none;
}

.menu-bar-icon {
  padding-right: 6%;
}

.custom-text-color {
  color: #ff8d00 !important; /* Text color */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 21.6px */
}

.custom-list-text-color {
  color: #fff;
  text-decoration: none !important;
  /* Desktop/En/Body/Body1/Regular */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-list-text-color {
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .MuiContainer-root {
    background-color: black;
    z-index: 1111;
    position: fixed;
  }

  .MainContainer {
    width: 100%;
  }

  .MuiPaper-root-MuiAppBar-root {
    background-color: black;
    width: 100% !important;
  }
  .logo {
    display: none;

    padding-left: 6%;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .logo-mob {
    display: block;
  }

  .menu-bar-icon {
    padding-right: 6%;
  }

  .custom-text-color {
    color: #ff8d00 !important; /* Text color */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 21.6px */
  }

  .custom-list-text-color {
    color: #fff;
    text-decoration: none !important;
    /* Desktop/En/Body/Body1/Regular */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .custom-list-text-color {
    text-decoration: none !important;
  }
}
