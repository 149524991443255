.our-services-page {
  padding-top: 12%;
  padding-left: 5%;
  padding-right: 5%;
  background: #000;
  padding-bottom: 8%;
}

.our-services-first-row {
}

.our-services-header-text {
  color: #ffffff;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 190%; /* 76px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.our-services-second-row {
}

.our-services-second-row-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-heading {
}

.our-services-heading h1 {
  color: #fff;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 95px */
  letter-spacing: 1px;
  text-transform: capitalize;
  padding-bottom: 4%;
}

.card-section {
  min-height: 386px;
}

.card-section h3 {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-transform: capitalize;
  padding-bottom: 3%;
}

.card-section h2 {
  color: #fff;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 51.2px */
  letter-spacing: 0.64px;
  padding-bottom: 3%;
}

.read-more-section {
  display: contents;
  justify-content: center;
  text-decoration: none;
  color: #fff;
}

.read-more {
  color: #000;
  text-align: center;
  width: 150px;
  /* text-sm/Medium */
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
  background: #fff;
}

@media (max-width: 767px) {
  .our-services-page {
    padding-top: 18%;
    padding-left: 5%;
    padding-right: 5%;
    background: #000;
    padding-bottom: 8%;
  }

  .our-services-first-row {
  }

  .our-services-header-text {
    color: #ffffff;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%; /* 76px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  .our-services-second-row {
  }

  .our-services-second-row-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-heading {
  }

  .our-services-heading h1 {
    color: #fff;
    font-family: Syne;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 95px */
    letter-spacing: 1px;
    text-transform: capitalize;
    padding-bottom: 4%;
  }

  .card-section {
    min-height: 386px;
  }

  .card-section h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-transform: capitalize;
    padding-bottom: 3%;
  }

  .card-section h2 {
    color: #fff;
    font-family: Syne;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 51.2px */
    letter-spacing: 0.64px;
    padding-bottom: 3%;
  }

  .read-more-section {
    display: contents;
    justify-content: center;
    text-decoration: none;
    color: #fff;
  }

  .read-more {
    color: #000;
    text-align: center;
    width: 150px;
    /* text-sm/Medium */
    font-family: Helvetica Neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 4px;
    background: #fff;
  }
}
