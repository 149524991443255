.about-us-page {
  padding-top: 12%;
  padding-left: 5%;
  padding-right: 5%;
  background: #000;
  padding-bottom: 8%;
}

.about-us-first-row {
}

.about-us-header-text {
  color: #ffbf00;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 190%; /* 76px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.about-us-second-row {
}

.about-us-second-row-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-heading {
}

.about-heading h1 {
  color: #fff;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 95px */
  letter-spacing: 1px;
  text-transform: capitalize;
}

.about-heading h2 {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
  text-transform: uppercase;
}

.about-heading h3 {
  color: #cbcbcb;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 190%; /* 30.4px */
  letter-spacing: 0.32px;

  padding-bottom: 3%;
}
.explore-button-section {
  display: contents;
  justify-content: center;
  text-decoration: none;
  color: #fff;
}

.explore-button {
  width: 246px;
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  text-decoration: none;
}

@media (max-width: 767px) {
  .about-us-page {
    padding-top: 18%;
    padding-left: 5%;
    padding-right: 5%;
    background: #000;
    padding-bottom: 8%;
  }

  .about-us-first-row {
  }
  .w-image {
    padding-top: 4%;
    padding-bottom: 8%;
  }

  .about-us-header-text {
    color: #ffbf00;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 76px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  .about-us-second-row {
  }

  .about-us-second-row-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-heading {
  }

  .about-heading h1 {
    color: #fff;
    font-family: Syne;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 95px */
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  .about-heading h2 {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 166.667% */
    text-transform: uppercase;
  }

  .about-heading h3 {
    color: #cbcbcb;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30.4px */
    letter-spacing: 0.32px;

    padding-bottom: 3%;
  }
  .explore-button-section {
    display: flex;
    justify-content: center;

    align-items: center;
    color: #fff;
  }

  .explore-button {
    text-decoration: none;
    width: 180px;
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
  }
}
