.information-container {
  padding-top: 12%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 8%;
  background: #000;
}

.informations-row {
  padding-bottom: 2%;
}

.informations-row h2 {
  padding-top: 3%;
  color: #ffbf00;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 76px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.informations-row p {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%; /* 200% */
  text-transform: capitalize;
}

.informations-row p span {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 200%;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .information-container {
    padding-top: 18%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 8%;
    background: #000;
  }

  .informations-row {
    padding-bottom: 2%;
  }

  .informations-row h2 {
    padding-top: 3%;
    color: #ffbf00;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 76px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  .informations-row p {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%; /* 200% */
    text-transform: capitalize;
  }

  .informations-row p span {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
    text-transform: capitalize;
  }
}
