.card-header {
  color: #000;
  font-family: Syne;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.apply-button {
  border-radius: 4px;
  background: #48259e !important;
}

.btn-btn {
  width: 100px;
  height: 17px;
  flex-shrink: 0;
  color: #656565 !important;
  text-align: center;
  font-family: Inter;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 3px;
  background: #efefef !important;
}

@media (max-width: 767px) {
  .card-col {
    padding-bottom: 4%;
  }
}
