.local-recruitment-component {
  padding-top: 12%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  background: #000;
}
.lrc-header-row {
}

.lrc-header-row h1 {
  color: #fff;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 190%; /* 133px */
  letter-spacing: 1.4px;
  text-transform: capitalize;
}

.lrc-header-row h2 {
  color: #fff;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 190%; /* 76px */
  letter-spacing: 0.8px;
  text-transform: capitalize;
}

.lrc-header-row h3 {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  text-transform: uppercase;
}

.lrc-header-row h4 {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
}

.lrc-details-row {
  padding-top: 3%;
  padding-bottom: 3%;
}

.lrc-details-row h1 {
  color: #ffbf00;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
}

.lrc-details-row p {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .local-recruitment-component {
    padding-top: 18%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    background: #000;
  }
  .lrc-header-row {
  }

  .lrc-header-row h1 {
    color: #fff;
    font-family: Syne;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 190%; /* 133px */
    letter-spacing: 1.4px;
    text-transform: capitalize;
  }

  .lrc-header-row h2 {
    color: #fff;
    font-family: Syne;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 190%; /* 76px */
    letter-spacing: 0.8px;
    text-transform: capitalize;
  }

  .lrc-header-row h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
  }

  .lrc-header-row h4 {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
  }

  .lrc-details-row {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .lrc-details-row h1 {
    color: #ffbf00;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    text-transform: uppercase;
  }

  .lrc-details-row p {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
  }
}
