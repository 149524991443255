@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Montserrat:ital,wght@0,400;0,600;0,700;1,300&family=Poppins:wght@200&family=Roboto&family=Rubik&display=swap");

.welcome-screen-container {
  background: #000;
}

.welcome-screen-row {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
  padding-top: 12%;
}

.welcome-screen-text {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px; /* 142.857% */
  text-transform: capitalize;
}
.welcome-screen-text span {
  color: #daa520;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  text-transform: capitalize;
}

.welcome-screen-img {
  width: 100%;
}

.first-section-start-btn {
  display: flex;
  width: 246px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #ffff;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #000;
}

.first-section-start {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 767px) {
  .welcome-screen-container {
    background: #000;
  }

  .welcome-screen-row {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 3%;
    padding-top: 18%;
  }

  .welcome-screen-text {
    color: #fff;
    // text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%; /* 142.857% */
    text-transform: capitalize;
  }
  .welcome-screen-text span {
    color: #daa520;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 142.857%; /* 142.857% */
    text-transform: capitalize;
  }

  .welcome-screen-img {
    width: 100%;
  }

  .first-section-start-btn {
    display: flex;
    width: 180px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #ffff;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: #000;
    font-size: 16px;
  }

  .first-section-start {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
