.career-block {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 12%;
  padding-bottom: 8%;
  background: #000;
}

.career-block-row h1 {
  color: #fff;
  font-family: Syne;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 190%; /* 95px */
  letter-spacing: 1px;
  text-transform: capitalize;
}

.career-block-row h3 {
  color: #fff;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 129.032% */
  text-transform: capitalize;
}

.career-cards-section {
  padding-top: 3%;
}

@media (max-width: 767px) {
  .career-block {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 18%;
    padding-bottom: 8%;
    background: #000;
  }

  .career-block-row h1 {
    color: #fff;
    font-family: Syne;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 95px */
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  .career-block-row h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 129.032% */
    text-transform: capitalize;
  }

  .career-cards-section {
    padding-top: 3%;
  }
}
