@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Montserrat:ital,wght@0,400;0,600;0,700;1,300&family=Poppins:wght@200&family=Roboto&family=Rubik&family=Syne:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Montserrat:ital,wght@0,400;0,600;0,700;1,300&family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;1,6..12,400&family=Nunito:wght@300;400&family=Poppins:wght@200&family=Roboto&family=Rubik&family=Syne:wght@400;500;600;700&display=swap");
.footer-section-container {
  padding-top: 8%;
  padding-left: 6%;
  padding-right: 6%;

  padding-bottom: 8%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.footer-section-main {
  padding-left: 0;
  padding-right: 0;
}

.footer-section-content {
}

.work-wizard-container h1 {
  color: #000;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}

.work-wizard-container p {
  font-size: 11px;
  color: #000;
  font-family: Lato;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 152.381% */
}

.work-wizard-container {
  max-width: 82.9218%;
}

.work-wizard-container h3 {
  font-size: 26px;
  font-family: "Gugi", cursive;
  font-weight: 400;
  line-height: 28.6px;
  margin-bottom: 12px;
}

.social-media-container .MuiButtonBase-root {
  min-width: 39px;
  width: 39px;
  height: 39px;
  padding-right: 4%;
}

.pages-container h4 {
  font-size: 18px;
  font-family: "Gugi", cursive;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
}

.pages-container h5 {
  font-size: 16px;
  font-family: "Rubik", cursive;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
}

.social-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages-container {
  align-items: center;
}

.info-container {
  align-items: center;
}

.info-container h4 {
  font-size: 18px;
  font-family: "Gugi", cursive;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
}

.info-container h5 {
  font-size: 16px;
  font-family: "Rubik", cursive;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
}

.info-container h3 {
  padding-left: 2%;
  color: #000;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;

  line-height: 110%; /* 28.6px */
}
.link-text {
  text-decoration: none;
}
.social-media-icons {
}

@media (max-width: 767px) {
  .footer-section-container {
    padding-top: 8%;
    padding-left: 6%;
    padding-right: 6%;

    padding-bottom: 8%;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }

  .footer-section-main {
    padding-left: 0;
    padding-right: 0;
  }

  .footer-section-content {
  }

  .work-wizard-container h1 {
    color: #000;
    font-family: Syne;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 24px; /* 100% */
  }

  .work-wizard-container p {
    font-size: 11px;
    color: #000;
    font-family: Lato;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 152.381% */
    text-align: center;
  }

  .work-wizard-container {
    max-width: 100%;
  }

  .work-wizard-container h3 {
    font-size: 26px;
    font-family: "Gugi", cursive;
    font-weight: 400;
    line-height: 28.6px;
    margin-bottom: 12px;
  }

  .social-media-container .MuiButtonBase-root {
    min-width: 39px;
    width: 39px;
    height: 39px;
    padding-right: 4%;
  }

  .pages-container h4 {
    font-size: 18px;
    font-family: "Gugi", cursive;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 12px;
  }

  .pages-container h5 {
    text-align: center;
    font-size: 16px;
    font-family: "Rubik", cursive;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
  }

  .social-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pages-container {
    align-items: center;
  }

  .info-container {
    align-items: center;
  }

  .info-container h4 {
    font-size: 18px;
    font-family: "Gugi", cursive;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 12px;
  }

  .info-container h5 {
    text-align: center;
    font-size: 16px;
    font-family: "Rubik", cursive;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
  }

  .info-container h3 {
    padding-left: 2%;
    color: #000;
    font-family: Nunito;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;

    line-height: 110%; /* 28.6px */
  }

  .link-text {
    text-decoration: none;
  }
}
