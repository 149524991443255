.contact-details h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */
  text-transform: capitalize;
}

.contact-details h2 {
  color: #fff;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
}

.contact-details h2 span {
  color: #ffbf00;
  font-family: Syne;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 100% */
  text-transform: uppercase;
}

.contact-details h4 {
}

.contact-details p {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .contact-details h1 {
    color: #fff;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 133.333% */
    text-transform: capitalize;
  }

  .contact-details h2 {
    color: #fff;
    font-family: Syne;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    text-transform: uppercase;
  }

  .contact-details h2 span {
    color: #ffbf00;
    font-family: Syne;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px; /* 100% */
    text-transform: uppercase;
  }

  .contact-details h4 {
  }

  .contact-details p {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
